
import { Component, Vue } from 'vue-property-decorator';
import { $enum } from 'ts-enum-util';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import EventsFilterService, { EventsFilterServiceS } from '@/modules/events/events-filter.service';
import EVENT_STATUS_SETTINGS from '@/modules/events/constants/event-statuses.constant';

@Component({
    components: { CustomSelect },
})
export default class EventStatusFilter extends Vue {
    @Inject(EventsFilterServiceS) private eventsFilterService!: EventsFilterService;

    get eventStatus(): EVENT_STATUS_SETTINGS {
        return this.eventsFilterService.settings.status;
    }

    set eventStatus(status: EVENT_STATUS_SETTINGS) {
        this.eventsFilterService.settings = { ...this.eventsFilterService.settings, status };
    }

    get eventStatusesItems(): Item[] {
        return $enum(EVENT_STATUS_SETTINGS).map((value: EVENT_STATUS_SETTINGS): Item => ({
            value,
            name: value.toUpperCase(),
        }));
    }
}
