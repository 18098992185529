
import EventStatusFilter from '@/modules/events/components/events-filters/event-status-filter.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import EventTypeFilter from '@/modules/events/components/events-filters/event-type-filter.vue';
import CountryFilter from '@/modules/events/components/events-filters/country-filter.vue';
import EventsFilterService, { EventsFilterServiceS } from '@/modules/events/events-filter.service';

@Component({
    components: {
        EventTypeFilter,
        CountryFilter,
        EventStatusFilter,
    },
})
export default class EventsActions extends Vue {
    @Inject(EventsFilterServiceS) private eventsFilterService!: EventsFilterService;
    @Inject(UserServiceS) private userService!: UserService;

    get createEventRoute() {
        if (this.userService.isCarUser) {
            return '/events-manager/new-event';
        }

        const routeBranch = this.$route.name!.split('.')[0];

        return {
            name: `${routeBranch}.events-manager.new`,
        };
    }
}
