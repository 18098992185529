
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import type Day from '@/modules/common/types/day.type';
import EventsModel from '@/modules/events/models/events.model';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import Calendar from '@/modules/common/components/ui-kit/calendar.vue';
import EventsCard from '@/modules/events/components/events-manager/events-card.vue';
import EventsManagerService, { EventsManagerServiceS } from '@/modules/events/events-manager.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';
import OpenTelemetryService, { OpenTelemetryServiceS } from '@/modules/open-telemetry/open-telemetry.service';
import { LOGTYPE } from '@/modules/open-telemetry/constants';
import EventGroup from '../../interfaces/event-group.enum';
import EVENT_STATUS_SETTINGS from '../../constants/event-statuses.constant';

interface DayEvent {
    isHoliday?: boolean;
    isMy?: boolean;
    isSuggested?: boolean;
    isChain?: boolean;
    event: EventsModel;
}

@Component({
    components: {
        LoaderWrapper,
        Calendar,
        EventsCard,
        CustomLoader,
    },
})
export default class EventsCalendar extends Vue {
    @Inject(HelperServiceS) helperService!: HelperService;
    @Inject(EventsManagerServiceS) private eventsManagerService!: EventsManagerService;
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(OpenTelemetryServiceS) private otelService!: OpenTelemetryService;

    private readonly pageLogName = 'events-calendar';

    beforeMount() {
        this.otelService.startSpan({ prefix: LOGTYPE.LOADING, name: this.pageLogName });
    }

    mounted() {
        if (this.eventsManagerService.isLoading) {
            return;
        }

        this.otelService.endSpan({ prefix: LOGTYPE.LOADING, name: this.pageLogName }, { sendLogs: true });
    }

    updated() {
        if (this.eventsManagerService.isLoading) {
            return;
        }

        this.otelService.endSpan({ prefix: LOGTYPE.LOADING, name: this.pageLogName }, { sendLogs: true });
    }

    get year(): number {
        return this.documentFiltersService.storeState.settings.year;
    }

    get month(): number {
        return this.documentFiltersService.storeState.settings.month;
    }

    get skeleton() {
        return this.eventsManagerService.isLoading;
    }

    getDayEvents(day: Day) {
        const { year, month } = this.documentFiltersService;
        const date = new Date(year, month, day);
        const { countries, status, types } = this.eventsManagerService.storeState.settings;
        const collection = this.eventsManagerService.getEventCollection(date);

        switch (status) {
            case EVENT_STATUS_SETTINGS.SUGGESTED:
                return collection?.suggested.filter(e => types.includes(e.type!)) || [];

            case EVENT_STATUS_SETTINGS.APPROVED:
                return [
                    ...collection?.my.filter(e => types.includes(e.type!)) || [],
                    ...collection?.chain.filter(e => types.includes(e.type!)) || [],
                ];

            default:
                return this.eventsManagerService.getEventsByDate(date)
                    .filter(event => countries.includes(event.country!) || event.group !== EventGroup.HOLIDAY);
        }
    }

    handleCalendarItemClick(day: Day) {
        const routeBranch = this.$route.name!.split('.')[0];

        const name = !this.userService.isCarUser
            ? `${routeBranch}.events-manager.day-event-modal`
            : 'events-manager.day-event-modal';

        this.$router.push({
            name,
            params: {
                ...this.$route.params,
                day: String(day),
            },
        });
    }

    viewEvent(e: Event, eventId: string) {
        e.stopPropagation();

        const routeBranch = this.$route.name!.split('.')[0];

        const name = !this.userService.isCarUser
            ? `${routeBranch}.events-manager.view`
            : 'events-manager.view';

        this.$router.push({
            name,
            params: {
                ...this.$route.params,
                eventId,
            },
        });
    }

    hiddenEvents(day: Day): number {
        const eventsCount = this.getDayEvents(day).length;
        return eventsCount > 2 ? eventsCount - 2 : 0;
    }
}
